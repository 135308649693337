export default defineNuxtRouteMiddleware((to, from) => {
    const jobsOpening = [
        {
            id: 1,
            state: 'open'
        },
        {
            id: 2,
            state: 'close'
        },
    ]
    if (/^\/careers\/contact\/\d+$/.test(to.path)) {
        const routeId = Array.isArray(to.params.id) ? parseInt(to.params.id[0]) : parseInt(to.params.id);

        const isClosedJob = jobsOpening.some(job => job.id === routeId && job.state === 'close');

        const notMatchingJob = !jobsOpening.some(job => job.id === routeId);
        if (isClosedJob || notMatchingJob)
            return navigateTo('/careers');
    }
});
